import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { authenticationService } from '../_services';

const Kurikulum = React.lazy(() => import('../containers/TheLayout'));
const PKLK = React.lazy(() => import('../containers/pklk/TheLayout'));

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser   = authenticationService.currentUserValue;
        const currentLevel  = authenticationService.currentUserValue != null ? authenticationService.currentUserValue.data.level : null;
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            // return <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
        }
        // authorised so return component
        if(currentLevel && (currentLevel == 2)){
            return <Kurikulum {...props} />
        } else if (currentLevel && (currentLevel == 96)) {
            return <PKLK {...props} />
        }
    }} />
)